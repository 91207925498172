import type { WETH } from '$lib/contract/typechain';
import { wethContractABI, wethContractAddress } from '$lib/contract/wethContract';
import { authenticatedUsingEmail, googleSession, keycloak, sessionExpired, userInfo } from '$lib/stores/userstore';
import type { GoogleAccessToken, KeycloakAuth, WalletSignature } from '$lib/types';
import { v4 as uuid } from '@lukeed/uuid';
import { BigNumber, ethers } from 'ethers';
import { AccountService } from './AccountService';
import { getGoogleTokenExchanged, getGoogleAccessToken } from './AxiosService';
import { validateAccountHandle } from './ErrorHandler';

export const getKeycloakInstance = async (_googleSession: GoogleAccessToken | undefined, _keycloak: KeycloakAuth | undefined) => {
	let defaultLoginURL = '';
	if (_keycloak) {
		return _keycloak;
	}
	try {
		if (typeof _googleSession === 'undefined') {
			throw new Error('user not logged in')
		}
		console.log('google accessToken:', _googleSession);

		const tokenResponse = await getGoogleTokenExchanged(_googleSession);

		const instance: KeycloakAuth = {
			googleAccessToken: _googleSession.access_token,
			token: tokenResponse.data.access_token,
			refreshToken: tokenResponse.data.refresh_token
		};

		authenticatedUsingEmail.set(true);
		keycloak.set(instance);

		const accountService = new AccountService(instance);
		const account = await accountService.account();
		userInfo.set(account);

		return { auth: true, instance, defaultLoginURL };
	} catch (error) {
		if (_googleSession) {
			sessionExpired();
		}
		const instance: KeycloakAuth = {
			googleAccessToken: '',
			token: '',
			refreshToken: ''
		};

		return { auth: false, instance, defaultLoginURL };
	}
};

export const forceLogin = () => {
	const client_id = import.meta.env.VITE_GOOGLE_CLIENT_ID;
	return new Promise<boolean>((resolve, reject) => {
		const codeClient = (window as any).google.accounts.oauth2.initCodeClient({
			client_id,
			scope: 'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
			callback: (codeResponse) => {
				console.log("codeResponse", codeResponse)
				getGoogleAccessToken(codeResponse.code).then((resp) => {
					googleSession.set(resp.data);
					console.log("codeResponse", resp.data)
					resolve(true)
				}, () => {
					reject()
				})
			}
		});
		codeClient.requestCode()
	});
};

export const signMessage = async (provider: any, account: string) => {
	if (!(window as any).ethereum) {
		return;
	}
	const library = new ethers.providers.Web3Provider(provider);
	const nonce = uuid();
	if (!!library.provider.request) {
		const singedMessage = await library.provider.request({
			method: 'personal_sign',
			params: [nonce, account]
		});
		return {
			nonce,
			singedMessage
		} as WalletSignature;
	} else {
		throw new Error('provider not initialised');
	}
};

export const getBalance = async () => {
	if (!(window as any).ethereum) {
		return ['', null];
	}
	const library = new ethers.providers.Web3Provider(window?.ethereum);
	const signer = library.getSigner();
	let balance = await library.getBalance(signer.getAddress());
	const remainder = balance.mod(1e14);
	return [ethers.utils.formatEther(balance.sub(remainder)), balance];
};

export const getWETHBalance = async () => {
	if (!(window as any).ethereum) {
		return ['', null];
	}
	const library = new ethers.providers.Web3Provider(window?.ethereum);
	const signer = library.getSigner();

	let wethContract = new ethers.Contract(wethContractAddress, wethContractABI, library) as WETH;
	const wethContractWithSigner = wethContract.connect(signer);
	let address = await signer.getAddress();
	let balance = await wethContractWithSigner.balanceOf(address);
	return [ethers.utils.formatEther(balance), balance];
};

export const convertToETH = async (amount: BigNumber, contractAddress: string, network: string) => {
	if (!(window as any).ethereum) {
		return;
	}
	await validateAccountHandle(network);
	const library = new ethers.providers.Web3Provider(window?.ethereum);
	const signer = library.getSigner();

	let wethContract = new ethers.Contract(contractAddress, wethContractABI, library) as WETH;
	const wethContractWithSigner = wethContract.connect(signer);

	let trans = await wethContractWithSigner.deposit({
		value: amount
	});
	await trans.wait(1);
};

export function genShortAddress(selectedAccount: string | undefined) {
	if (!!selectedAccount) {
		return `${selectedAccount.substring(0, 6)}...${selectedAccount.substring(
			selectedAccount.length - 4,
			selectedAccount.length
		)}`;
	}
	return '';
}

export function getBuyerOrSeller(
	selectedAccount: string | undefined,
	to: string | undefined,
	from: string | undefined
) {
	if (!!selectedAccount && !!to && selectedAccount.toLowerCase() !== to.toLowerCase()) {
		return `${to.substring(0, 6)}...${to.substring(to.length - 4, to.length)}`;
	} else if (!!from) {
		return `${from.substring(0, 6)}...${from.substring(from.length - 4, from.length)}`;
	}
	return '';
}

export function formatPrice(balance: string) {
	return Math.round(parseFloat(ethers.utils.formatEther(`${balance}`)) * 1e4) / 1e4;
}

export async function copyAdreessToClipboard(selectedAccount: string | undefined) {
	if (!!selectedAccount) {
		// Navigator clipboard api needs a secure context (https)
		if (navigator.clipboard && window.isSecureContext) {
			await navigator.clipboard.writeText(selectedAccount);
		} else {
			// Use the 'out of viewport hidden text area' trick
			const textArea = document.createElement("textarea");
			textArea.value = selectedAccount;

			// Move textarea out of the viewport so it's not visible
			textArea.style.position = "absolute";
			textArea.style.left = "-999999px";

			document.body.prepend(textArea);
			textArea.select();

			try {
				document.execCommand('copy');
			} catch (error) {
				console.error(error);
			} finally {
				textArea.remove();
			}
		}
	}
}

export function dateFormatter(date: string | null) {
	if (!!date) {
		const obj = new Date(parseInt(date) * 1000);
		return dateObjFormatter(obj);
	}
	return '';
}

export function dateObjFormatter(obj: Date) {
	let monthStr = (obj.getMonth() + 1).toString().padStart(2, '0');
	let dayStr = obj.getDate().toString().padStart(2, '0');
	let HoursStr = obj.getHours().toString().padStart(2, '0');
	let SecondsStr = obj.getSeconds().toString().padStart(2, '0');
	let MillisecondsStr = obj.getMilliseconds().toString().padStart(2, '0');
	return `${obj.getFullYear()}.${monthStr}.${dayStr} ${HoursStr}:${SecondsStr}:${MillisecondsStr}`;
}

export function numberWithCommas(numberString: string) {
	if (numberString.indexOf('.') > -1) {
		var parts = numberString.toString().split('.');
		if (parts[1].length > 3) {
			parts[1] = parts[1].substring(0, 3);
		}
		return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + parts[1];
	} else {
		return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
}
