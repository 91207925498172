import type {
	Account,
	Credential,
	DeleteWalletRequest,
	KeycloakAuth,
	Wallet,
	WalletRequest
} from '$lib/types';
import type { AxiosInstance } from 'axios';
import { REALM } from '../../constants';
import { getDefaultAxiosWithToken } from './AxiosService';

export class AccountService {
	keyCloack: KeycloakAuth;
	axios: AxiosInstance;
	accountURL = `/auth/realms/${REALM}/account`;

	constructor(keyCloack: KeycloakAuth) {
		this.keyCloack = keyCloack;
		this.axios = getDefaultAxiosWithToken(keyCloack);
	}

	async account() {
		const resp = await this.axios.get<Account>(this.accountURL);
		return resp.data;
	}

	async updateAccount(account: Account) {
		const resp: any = await this.axios.post<Account, void, Account>(this.accountURL, account, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return resp;
	}

	async credentials() {
		const url = this.accountURL + '/credentials';
		return this.axios.get<Credential[]>(url);
	}

	async wallets(username: string) {
		const url = `/auth/realms/${REALM}/wallet-resource-provider/byUsername/${username}`;
		let resp = await this.axios.get<Wallet[]>(url);
		return resp.data;
	}

	async walletsByAddress(address: string | undefined) {
		const url = `/auth/realms/${REALM}/wallet-resource-provider/byAddress/${address}`;
		let resp = await this.axios.get<Wallet[]>(url);
		return resp.data;
	}

	async addWallets(wallet: WalletRequest) {
		const url = `/auth/realms/${REALM}/wallet-resource-provider`;
		let resp = await this.axios.post<Wallet, Wallet, WalletRequest>(url, wallet);
		return resp;
	}

	async deregisterWallet(address: string, username: string, req: DeleteWalletRequest) {
		const url = `/auth/realms/${REALM}/wallet-resource-provider/byAddress/${address}/user/${username}`;
		let resp = await this.axios.delete<void, DeleteWalletRequest>(url, {
			data: req
		});
		return resp;
	}
}

export function toShortAddress(address: string) {
	return `${address.substring(0, 5)}...${address.substring(address.length - 4, address.length)}`;
}

export const getName = (userInfo: Account | undefined) => {
	if (!!userInfo) {
		return userInfo.firstName + ' ' + userInfo.lastName;
	}
	return '';
};

export const getEmail = (userInfo: Account | undefined) => {
	if (!!userInfo) {
		return userInfo.email;
	}
	return '';
};
